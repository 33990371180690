import { createReducer, on } from '@ngrx/store';
import { PersonDetailsForm } from '@qivos/qivoscloud-ion-components/lib/models/person-details.model';
import { Person } from '@qivos/qivoscloud-ng-api';
import {
  initMobileNumber,
  searchPersonByTelephoneSuccess,
} from '../actions/member-details-form.actions';

export interface MemberDetailsState extends PersonDetailsForm {
  person: Person;
  termsStatus: boolean;
}

const today = new Date();

const minValidDate = new Date();
minValidDate.setFullYear(today.getFullYear() - 18);
const calculateLastDayOfCurrentMonth = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0
);
minValidDate.setDate(calculateLastDayOfCurrentMonth.getDate());
minValidDate.setMonth(today.getMonth());
const dateOfBirthMaxValue = minValidDate.toISOString();

const maxValidDate = new Date();
maxValidDate.setFullYear(today.getFullYear() - 95);
const dateOfBirthMinValue = maxValidDate.toISOString();

const defDate = new Date();

defDate.setDate(0);
defDate.setMonth(11);
defDate.setFullYear(1927);

const defValue = defDate.toISOString();

const initialState: MemberDetailsState = {
  person: new Person(),
  mobileNumber: {
    rendered: true,
    required: false,
    label: '',
    placeholder: '',
    value: '',
    validation: / /,
    message: '',
  },
  gender: {
    rendered: false,
    required: false,
    label: '',
    maleGenderLabel: '',
    femaleGenderLabel: '',
    value: { Name: '', Value: '' },
  },
  firstName: {
    rendered: false,
    required: false,
    label: '',
    placeholder: '',
    value: '',
    validation: / /,
    message: '',
  },
  lastName: {
    rendered: false,
    required: false,
    label: '',
    placeholder: '',
    value: '',
    validation: / /,
    message: '',
  },
  email: {
    rendered: true,
    required: true,
    label: 'Email',
    placeholder: 'Πληκτρολόγησε το email σου',
    value: '',
    validation:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@[A-Za-z0-9.-]{2,})+\.[A-Za-z]{2,}/,
    message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
  },
  region: {
    rendered: false,
    required: false,
    label: '',
    placeholder: '',
    value: '',
    message: '',
  },
  town: {
    rendered: false,
    required: false,
    label: '',
    placeholder: '',
    value: '',
  },
  dateOfBirth: {
    rendered: false,
    required: false,
    label: '',
    placeholder: '',
    datePickerDoneButtonLabel: '',
    datePickerCancelButtonLabel: '',
    value: '',
    minValue: dateOfBirthMinValue,
    maxValue: dateOfBirthMaxValue,
    message: '',
  },
  nameDay: {
    rendered: false,
    required: false,
    placeholder: '',
    label: '',
    value: '',
  },
  singleConsent: {
    rendered: false,
    required: false,
    label: '',
  },
  smsConsent: {
    rendered: false,
    value: false,
  },
  viberConsent: {
    rendered: false,
    value: false,
  },
  emailConsent: {
    rendered: false,
    value: false,
  },
  multiConsent: {
    rendered: false,
    required: false,
    label: '',
    value: false,
  },
  termsStatus: false,
};

export const MemberDetailsReducer = createReducer(
  initialState,
  on(searchPersonByTelephoneSuccess, (state, { persons }) => ({
    ...state,
    person: persons !== undefined ? persons[0] : { ...state.person },
  })),
  on(initMobileNumber, (state, { mobileNumber }) => ({
    ...state,
    mobileNumber: { ...state.mobileNumber, value: mobileNumber },
  }))
);
