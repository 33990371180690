import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'member-details',
    loadChildren: () =>
      import('./pages/member-details-form/member-details-form.module').then(
        (m) => m.MemberDetailsFormPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundPageModule
      ),
  },
  {
    path: 'register-successfully',
    loadChildren: () =>
      import('./pages/register-successfully/register-successfully.module').then(
        (m) => m.RegisterSuccessfullyPageModule
      ),
  },
  {
    path: 'email-already-exists',
    loadChildren: () =>
      import('./pages/email-already-exists/email-already-exists.module').then(
        (m) => m.EmailAlreadyExistsModule
      ),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
